export const FirebaseConfig = {
	"projectId": "chame-guincho-7b2f3",
	"appId": "1:142289421216:web:85b5bcfb0d93379e3bdac5",
	"databaseURL": "https://chame-guincho-7b2f3-default-rtdb.firebaseio.com",
	"storageBucket": "chame-guincho-7b2f3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDPShZoXhbqIh5TU_aKZm0HjBQCeVQw3IE",
	"authDomain": "chame-guincho-7b2f3.firebaseapp.com",
	"messagingSenderId": "142289421216",
	"measurementId": "G-Z0B16FKKRP"
};